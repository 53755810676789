@import url("https://fonts.googleapis.com/css2?family=Titan+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Titan+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mouse+Memoirs&family=Sofia+Sans:ital,wght@0,1..1000;1,1..1000&display=swap");
@font-face {
  font-family: "W95";
  src: url("../public/W95FA.otf") format("opentype");
}

/* For Chrome, Safari, and Edge */
::-webkit-scrollbar {
  width: 6px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #000000; /* Background of the scrollbar track */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Adds padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Mouse Memoirs", sans-serif;
  /* scroll-behavior: smooth; */
}

:root {
  --main-gray: gray;
}
