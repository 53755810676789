.home {
  display: flex;
  flex-direction: column;
}
.header {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0rem;
}

nav {
  height: 3.75rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 500;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding-right: 1rem;
}

nav button {
  background-color: #f0f;
  font-family: Titan One;
  border: none;
  outline: none;
  padding: 0.75rem;
  border-radius: 0.25rem;
  color: #fff;
  text-shadow: -1.5px -1.5px 0 black, 1.5px -1.5px 0 black, -1.5px 1.5px 0 black,
    1.5px 1.5px 0 black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 15px;
  box-shadow: 0 0 5px #000;
  z-index: 20;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

nav button:hover {
  background-color: rgba(255, 0, 255, 0.764);
}

.bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
  position: absolute;
}

.hero {
  z-index: 100;
  color: rgb(255, 0, 255);
  text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white,
    2px 2px 0 white;
  font-size: 7rem;
  letter-spacing: 7px;
  line-height: 75px;
}

.subHero {
  z-index: 100;
  color: rgb(255, 0, 255);
  text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white,
    2px 2px 0 white;
  font-size: 6vw;
  letter-spacing: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  padding-top: 1rem;
}

.solana {
  z-index: 100;
  color: rgb(255, 0, 255);
  text-shadow: none !important;
  font-size: 6vw;
  letter-spacing: 7px;

  font-weight: bold; /* Optional, for a bolder effect */
  background: linear-gradient(250deg, #9945ff, #9945ff, #14f195, #14f195);
  -webkit-background-clip: text; /* Chrome, Safari */
  background-clip: text; /* Standard */
  color: transparent; /* Hide the default text color */
}

.mc {
  height: 53.2rem;
  width: 100%;
  position: relative;
  z-index: 100;
  overflow: hidden;
}

.milady {
  background-color: transparent;
  width: 50rem;
  position: absolute;
  bottom: -23px;
  left: 0;
}

.error {
  position: absolute;
  z-index: 10;
  left: 14rem;
  top: 10rem;
  width: 12.75rem;
  height: 12.75rem;
  z-index: 20;
}

.boomWrap {
  position: absolute;
  left: 40vw;
}

.boom {
  position: absolute;
  z-index: 10;
  left: 43%;
  top: 0rem;
  width: 40rem;
  height: 40rem;
  transform: rotate(30deg);
  opacity: 0.3;
}

.disney {
  height: 60rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.xan {
  position: absolute;
  z-index: 20;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  inset: 0;
  animation: fader 2s linear infinite;
}

@keyframes fader {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.05;
  }
}

.disneyHero {
  z-index: 10;
  font-size: 5rem;
  color: rgb(0, 162, 0);
  text-shadow: -1.5px -1.5px 0 white, 1.5px -1.5px 0 white, -1.5px 1.5px 0 white,
    1.5px 1.5px 0 white;
  transform: skew(2rem);
  display: flex;
  justify-content: center;
  align-items: center;
}
.disneyHero2 {
  z-index: 10;
  font-size: 4rem;
  color: purple;
  text-shadow: -1.5px -1.5px 0 white, 1.5px -1.5px 0 white, -1.5px 1.5px 0 white,
    1.5px 1.5px 0 white;
  transform: skew(20deg);
  animation: flashy 0.2s linear infinite;
  text-align: center;
}

@keyframes flashy {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.copy {
  font-family: initial;
}

.pyramid {
  height: 54rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden !important;
}

.solLogo {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  z-index: 20;
}

.marquee1 {
  transform: rotate(-65deg) !important;
  top: 14rem;
  left: -10rem;
  position: absolute;
  z-index: 50;
}

.marquee2 {
  transform: rotate(70deg) !important;
  top: 9rem;
  right: -12rem;
  position: absolute;
  z-index: 50;
}

.marquee3 {
  transform: rotate(180deg) !important;
  bottom: -20rem;
  left: 0rem;
  position: absolute;
  z-index: 50;
}

.barbie {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 10;
}

.centerBox {
  width: 40rem;
  height: 40rem;
  /* border: 1px solid red; */
  position: relative;
  border-radius: 20%;
  /* animation: rotater 3s linear infinite; */
  /* background-color: red; */
  overflow: hidden;
  /* background-color: transparent; */
}

@keyframes rotater {
  0% {
    rotate: 0deg;
    transform: translateX(-5rem);
  }

  50% {
    transform: translateX(5rem);
  }

  100% {
    rotate: 360deg;
    transform: translateX(-5rem);
  }
}

.bibFly {
  position: absolute;
  top: 30%;
  left: 22%;
  z-index: 200;
  box-sizing: 0px 0px 10px white;

  animation: hover 2s linear infinite;
}

@keyframes hover {
  0% {
    transform: translateY(0rem);
  }

  50% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0);
  }
}

.pyramidSpan {
  bottom: 8rem;
  position: absolute;
  font-size: 2.25rem;
  left: 0rem;
  width: 100%;
  text-align: center;
  color: rgb(255, 0, 255);
  text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white,
    2px 2px 0 white;
  z-index: 200;
}

/* .pyramid::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: black;
  background: linear-gradient(90deg, black, rgba(0, 0, 0, 0.85));
  z-index: 200;
  opacity: 0.7;
} */

.contestants {
  height: 60rem;
  background: linear-gradient(
    180deg,
    black,
    #14f195,
    #14f195,
    #9945ff,
    #9945ff,
    black
  );
  background-image: url("../../../public/Grid.webp");
  /* overflow-x: hidden !important; */
  padding-top: 10rem;
  position: relative;
}
.contestants::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: black;
  background: linear-gradient(
    180deg,
    black,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
  /* z-index: 10; */
  opacity: 0.7;
  top: 0;
}

.heroDropdown {
  height: 17.5rem;
  /* background-image: url("../../../public/Grid.webp"); */
  position: relative;
  /* background-color: red; */
  margin-top: -15rem;
  position: relative;
}

.hero2 {
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Mouse Memoirs", sans-serif;
  color: white;
  text-shadow: -3px -3px 0 black, 3px -3px 0 black, -3px 3px 0 black,
    3px 3px 0 black;
  letter-spacing: 6px;
  z-index: 20;
  top: 8rem;
  position: sticky;
  z-index: 1;
}

.midBLock {
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
}

.bathing {
  width: 100%;
  border-radius: 0.5rem;
  border: 5px solid black;
  z-index: 20;
  position: absolute;
  width: 400px;
  height: 200px;
}

.bathwrapper {
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
}

.phat {
  z-index: 10;
  position: absolute;
  width: 75px;
  left: 150px;
  top: 20px;
}

.imgWrapper {
  position: relative;
  width: 400px;
  height: 200px;
}

.vs {
  font-size: 48px;
  padding: 0px 3rem;
  font-family: "Titan One", sans-serif;
  text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white,
    2px 2px 0 white;
  z-index: 20;
  max-width: 10rem;
}

.turbotitle {
  position: absolute;
  text-align: center;
  font-family: "Titan One", sans-serif;
  text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white,
    2px 2px 0 white;
  letter-spacing: 3px;
  bottom: -2rem;
  width: 100%;
  text-align: center;
}

.rightGrid {
  display: flex;
  flex-wrap: wrap;
  width: 25rem;
  border: 5px solid black;
  align-items: center;
  padding-top: 7px;
  padding-left: 10px;
  border-radius: 1rem;
  z-index: 20;
}

.block {
  height: 3rem;
  /* border: 2px solid black; */
  border-radius: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0rem 0.9rem;
  background-color: #9945ff;
  color: white;
  margin-bottom: 0.5rem;
  user-select: none;
  flex: 1;
  gap: 0.25rem;
  margin-right: 0.5rem;
  font-weight: 500;
  font-family: "Titan One";
}

.cmc {
  width: 40px;
}

.gecko {
  width: 30px;
}

.ray {
  background-color: black;
  color: white;
  width: 44%;
  flex: 1;
}

.innerRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  z-index: 30;
}

.memeList {
  width: 100%;
  min-height: 185px;
  height: 10rem;
  border: 5px solid black;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-content: center;
  z-index: 20;
}

.bar {
  width: 10px;
  height: 1rem;
  background-color: black;
  z-index: 20;
}

.memeTile {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: inline;
}

.coinImg {
  border: 2px solid black;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  z-index: 30;
}

.capyBlock {
  width: 400px;
}

.barbie2 {
  width: 30rem;
  height: 20rem;
  position: absolute;
  top: 40rem;
  left: 0;
  opacity: 0.5;
  z-index: 1;
}

.windows {
  width: 100%;
  height: 100vh;
  background-color: #018281;
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.startBar {
  position: absolute;
  width: 100vw;
  height: 35px;
  background-color: rgb(192, 192, 192);
  border-top: 3px solid rgb(217, 225, 219);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  user-select: none;
  left: 0;
  bottom: 0;
}

.timeBox {
  position: relative;
  margin-right: 3px;
  top: 0px;
  width: 93px;
  height: 29px;
  background-color: transparent;
  border: 1px solid black;
  border-top: 2px solid rgb(145, 145, 145);
  border-left: 2px solid rgb(145, 145, 145);
  border-bottom: 2px solid rgb(251, 250, 251);
  border-right: 2px solid rgb(251, 250, 251);
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.clockIcon {
  position: relative;
  width: 22px;
  left: 3px;
  top: -1px;
}

.currentTime {
  font-family: "W95";
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
}

.startCta {
  position: relative;
  height: 27px;
  width: 65px;
  border-top: 2px solid rgb(251, 250, 251);
  border-left: 2px solid rgb(251, 250, 251);
  border-right: 2px solid rgb(20, 20, 20);
  border-bottom: 2px solid rgb(20, 20, 20);
  left: 3px;
  top: 1px;
  cursor: pointer;
  text-shadow: 0.5px 0px black, 1.5px 0px black;
  letter-spacing: 1px;
  background-size: cover;
  background-repeat: no-repeat;
  user-select: none;
  user-select: none;
}

.startImg {
  width: 100%;
}

.startActive {
  border-top: 3px solid black;
  border-left: 2px solid black;
  border-right: 2px solid rgb(251, 250, 251);
  border-bottom: 2px solid rgb(251, 250, 251);
}

.menu {
  position: absolute;
  width: 185px;
  height: 16.8rem;
  background-color: rgb(195, 199, 203);
  bottom: 36px;
  left: 1px;
  z-index: 10;
  border-top: 2px solid rgb(251, 250, 251);
  border-left: 2px solid rgb(251, 250, 251);
  border-right: 2px solid rgb(20, 20, 20);
  border-bottom: 2px solid rgb(20, 20, 20);
  user-select: none;
  z-index: 50 !important;
}

.leftBar {
  position: absolute;
  height: 100%;
  width: 24px;
  left: 1px;
}

.win95 {
  width: 100%;
  height: 100%;
}

.startList {
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 9.7rem;
  left: 25px;
  height: 16.5rem;
}

.startItem {
  width: 100%;
  height: 37px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-right: 1px;
}

.startItem:hover {
  background-color: rgb(0, 0, 170);
}

.startItem:hover span {
  color: white;
}
.startItem:hover .startXIcon {
  color: white;
}

.startIcon {
  width: 30px;
  top: 5px;
  position: absolute;
  left: 5px;
}

.startXIcon {
  top: 10px;
  position: absolute;
  left: 15px;
}

.startSpan {
  position: absolute;
  font-family: W95;
  font-size: 15px;
  left: 47px;
  top: 12px;
  color: black;
  text-decoration: none;
}

u {
  font-family: W95;
}

.border {
  border-top: 1px solid black;
  border-bottom: 2px solid rgb(251, 250, 251);
  position: relative;
  width: 98%;
}

.addressinput {
  display: none;
}

.iconsBar {
  position: absolute;
  height: calc(100% - 36px);
  left: 1rem;
  width: 8rem;
  /* background-color: red; */

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  bottom: 36px;
  flex-wrap: wrap;
  gap: 2.25rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
  padding-left: 0rem;
}

.iconDiv {
  width: 6.35rem;
  height: 3.8rem;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.6rem;
  user-select: none;
  text-align: center;
  cursor: pointer;
  z-index: 0 !important;
  padding-bottom: 0.4rem;
}

.iconTxt {
  font-size: 14px;
  font-family: W95;
  color: white;
  line-height: 14px;
}

.deskIcon {
  width: 2.5rem;
}

.winModal {
  position: absolute;
  width: 100%;
  top: 0;
  height: calc(100% - 33px);
  background-color: #c7c7c7;
  border: 1px solid black;
  border-top: 1px solid rgb(240, 240, 240);
  border-left: 1px solid rgb(240, 240, 240);
  z-index: 30;
  overflow: hidden;
}

.topBar {
  background-color: rgb(0, 0, 170);
  position: relative;
  height: 18px;
  margin: 1px 2px 0px 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.leftBlock {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
}

.microIcon {
  width: 17px;
  user-select: none;
}

.modalTitle {
  position: relative;
  font-family: W95;
  color: white;
  font-size: 13px;
  padding-left: 2px;
  user-select: none;
}

.rightBlock {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2px;
}

.rightBlock button {
  position: relative;
  width: 18px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: border-box;
  cursor: pointer;
  /* border: 1px solid transparent; */
}
.rightBlock button:nth-child(1) {
  background-image: url("../../../public/Minimize.png");
}
.rightBlock button:nth-child(2) {
  background-image: url("../../../public/Enlarge.png");
}
.rightBlock button:nth-child(3) {
  background-image: url("../../../public/Close.png");
}

.miniIcon {
  width: 100%;
  height: 100%;
}

.modalBody {
  position: relative;
  width: 100%;
  height: calc(100% - 20px);
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.solitaireIcon {
  width: 70px;
  margin-bottom: -1rem;
}

.mahjongIcon {
  width: 40px;
}

.doomIcon {
  width: 50px;
  margin-bottom: -12px;
}

.barbieIcon {
  width: 40px;
}

.pictureGrid {
  width: 100%;
  background-image: url("../../../public/Grid.webp");
}

.masonImg {
  border-radius: 0.5rem;
  margin: 10px;
}

@media only screen and (max-width: 1520px) {
  .milady {
    width: 30rem;
  }
}

@media only screen and (max-width: 1100px) {
  .boomWrap {
    transform: scale(0.9);
    left: 30vw;
  }

  .boom {
    transform: rotate(0deg);
  }
  .barbie2 {
    width: 20rem;
    left: -2rem;
    top: 46rem;
  }
}

@media only screen and (max-width: 650px) {
  .milady {
    width: 25rem;
    left: -2rem;
  }
  .boomWrap {
    left: 3rem;
  }
  .boom {
    transform: rotate(-10deg);
  }
  .centerBox {
    overflow: initial;
  }
}

@media only screen and (max-width: 480px) {
  .subHero {
    font-size: 4.5vw;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
      1px 1px 0 white;
  }
  .solana {
    font-size: 4.5vw;
  }
  .boomWrap {
    left: 0rem;
  }
  .bg {
    object-fit: initial;
  }
  .error {
    left: 12rem;
  }
  .disneyHero {
    font-size: 4rem;
  }

  .disneyHero2 {
    font-size: 11.5vw;
  }
  .xan {
    width: 150%;
  }
  .pyramidSpan {
    font-size: 2rem;
  }

  .marquee1 {
    top: 9rem;
  }
  .marquee2 {
    top: 4rem;
  }
  .barbie {
    width: 170%;
    left: -30%;
  }

  .imgWrapper {
    width: 100%;
    padding-bottom: 3rem;
  }

  .bathing {
    width: 95%;
  }

  .vs {
    line-height: 30px;
    top: 20px;
    position: relative;
  }
}

@media (max-width: 1250px) {
  .hero2 {
    font-size: 38px;
    line-height: 36px;
    padding: 0rem 1rem;
  }
  .contestants {
    padding-top: 7rem;
  }
}

@media (max-width: 1000px) {
  .midBLock {
    flex-direction: column;
    height: auto;
    padding-top: 3rem;
    padding-bottom: 7rem;
  }
  .vs {
    padding: 2rem 0rem;
    padding-top: 3rem;
  }
  .contestants {
    height: auto;
  }
  .block {
    background-color: #14f195;
    color: black;
    border: 2px solid black;
  }
  .ray {
    color: white;
    background-color: black !important;
  }
  nav {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0;
  }
}

@media (max-width: 420px) {
  .midBLock {
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 0;
    padding-top: 3rem;
    padding-bottom: 7rem;
  }
  .innerRight {
    width: 100%;
  }
  .rightGrid {
    width: 100%;
  }
  .vs {
    padding: 2rem 0rem;
  }
  .contestants {
    height: auto;
  }

  .hero2 {
    font-size: 32px;
    line-height: 32px;
  }
  .capyBlock {
    width: 95%;
    margin: 0rem 1rem;
  }
}

@media (max-width: 400px) {
  .hero2 {
    font-size: 28px;
  }
  .innerRight {
    transform: scale(0.9);
  }
  .vs {
    padding-bottom: 1rem;
  }
  .memeTile,
  .coinImg {
    width: 37.5px;
    height: 37.5px;
  }
}
